export const menuItems = [
  // {
  //   id: 0,
  //   label: "page.main",
  //   icon: "home",
  //   link: "/",
  //   // requiresRoles: ["a", "v", "d", "c"],
  // },

  // Admin pages
  {
    id: 10,
    label: "page.admin.users",
    icon: "handshake",
    link: "/admin/users",
    requiresAuth: true,
    requiresRoles: ["a"],
  },
  {
    id: 11,
    label: "page.admin.stats",
    icon: "info",
    link: "/admin/stats",
    requiresAuth: true,
    requiresRoles: ["a"],
  },
  {
    id: 12,
    label: "page.admin.companies",
    icon: "account_circle",
    link: "/admin/companies",
    requiresAuth: true,
    requiresRoles: ["a"],
  },

  // Company pages
  {
    id: 20,
    label: "page.company.users",
    icon: "handshake",
    link: "/company/users",
    requiresAuth: true,
    requiresRoles: ["c"],
  },
  {
    id: 21,
    label: "page.trackers",
    icon: "quiz",
    link: "/company/trackers",
    requiresAuth: true,
    requiresRoles: ["c"],
  },
  {
    id: 22,
    label: "page.company.stats",
    icon: "info",
    link: "/company/stats",
    requiresAuth: true,
    requiresRoles: ["c"],
  },

  // // Vendor pages
  // {
  //   id: 20,
  //   label: "Orders",
  //   icon: "list_alt",
  //   requiresAuth: true,
  //   requiresRoles: ["v"],
  //   isMenuCollapsed: false,
  //   subItems: [
  //     {
  //       id: 201,
  //       label: "Available orders",
  //       link: "/vendor/orders/available",
  //     },
  //     {
  //       id: 202,
  //       label: "Orders history",
  //       link: "/vendor/orders/history",
  //     },
  //   ],
  // },

];
