var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"common-modal-headline"},[_c('h4',{staticClass:"common-modal-title"},[_vm._v(" "+_vm._s(_vm.isNew ? _vm.$t('company.add-new-company') : _vm.$t('company.edit-company'))+" ")]),_c('span',{staticClass:"material-symbols-rounded common-modal-close",on:{"click":function($event){return _vm.$emit('onClose')}}},[_vm._v(" close ")])]),_c('hr',{staticClass:"my-3"}),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":"","show":Boolean(_vm.error)}},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('form.company-name')))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
          },attrs:{"placeholder":_vm.$t('form.company-name-placeholder')},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.name.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.company-name-req'))+" ")]):_vm._e(),(!_vm.$v.form.name.minLength)?_c('div',[_vm._v(_vm._s(_vm.$t('form.company-name-min', { min: 5 })))]):_vm._e(),(!_vm.$v.form.name.maxLength)?_c('div',[_vm._v(_vm._s(_vm.$t('form.company-name-max', { max: 64 })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('form.fio')))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fio),expression:"form.fio"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.fio.$error,
          },attrs:{"placeholder":_vm.$t('form.fio-placeholder')},domProps:{"value":(_vm.form.fio)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fio", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.fio.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.fio.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.fio-req'))+" ")]):_vm._e(),(!_vm.$v.form.fio.minLength)?_c('div',[_vm._v(_vm._s(_vm.$t('form.fio-min', { min: 5 })))]):_vm._e(),(!_vm.$v.form.fio.maxLength)?_c('div',[_vm._v(_vm._s(_vm.$t('form.fio-max', { max: 64 })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-0"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('form.timezone')))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('multiselect',{staticClass:"w-100",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.timezone.$error,
            },attrs:{"options":_vm.timezones,"multiple":false,"group-values":"zones","group-label":"group","group-select":false,"allow-empty":false,"maxHeight":240,"tag-placeholder":"","placeholder":_vm.$t('form.timezone-placeholder')},model:{value:(_vm.form.timezone),callback:function ($$v) {_vm.$set(_vm.form, "timezone", $$v)},expression:"form.timezone"}}),(_vm.submitted && _vm.$v.form.timezone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.timezone.required)?_c('div',[_vm._v(_vm._s(_vm.$t('form.timezone-req')))]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('form.phone')))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.phone.$error,
          },attrs:{"placeholder":_vm.$t('form.phone-placeholder')},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.phone.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.phone-req'))+" ")]):_vm._e(),(!_vm.$v.form.phone.minLength)?_c('div',[_vm._v(_vm._s(_vm.$t('form.phone-min', { min: 5 })))]):_vm._e(),(!_vm.$v.form.phone.maxLength)?_c('div',[_vm._v(_vm._s(_vm.$t('form.phone-max', { max: 20 })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{class:{ required: _vm.isNew }},[_vm._v(_vm._s(_vm.$t('form.email')))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{
              'is-invalid': _vm.isNew && _vm.submitted && _vm.$v.form.email.$error,
            },attrs:{"readonly":!_vm.isNew,"placeholder":_vm.$t('form.email-placeholder')},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.isNew && _vm.submitted && _vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.email.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.email-req'))+" ")]):_vm._e(),(!_vm.$v.form.email.email)?_c('div',[_vm._v(_vm._s(_vm.$t('form.email-not-valid')))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-0"},[_c('label',{class:{ required: _vm.isNew }},[_vm._v(_vm._s(_vm.$t('form.password')))]),_c('div',{staticClass:"input-group input-group-merge"},[((_vm.showPassw ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
              'is-invalid': _vm.isNew && _vm.submitted && _vm.$v.form.password.$error,
            },attrs:{"placeholder":_vm.$t('form.password-placeholder'),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.password)?_vm._i(_vm.form.password,null)>-1:(_vm.form.password)},on:{"change":function($event){var $$a=_vm.form.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "password", $$c)}}}}):((_vm.showPassw ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
              'is-invalid': _vm.isNew && _vm.submitted && _vm.$v.form.password.$error,
            },attrs:{"placeholder":_vm.$t('form.password-placeholder'),"type":"radio"},domProps:{"checked":_vm._q(_vm.form.password,null)},on:{"change":function($event){return _vm.$set(_vm.form, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
              'is-invalid': _vm.isNew && _vm.submitted && _vm.$v.form.password.$error,
            },attrs:{"placeholder":_vm.$t('form.password-placeholder'),"type":_vm.showPassw ? 'text' : 'password'},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",attrs:{"role":"button"},on:{"click":function($event){_vm.showPassw = !_vm.showPassw}}},[_c('div',{staticClass:"input-group-text"},[(_vm.showPassw)?_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility")]):_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility_off")])])]),(_vm.isNew && _vm.submitted && _vm.$v.form.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.password.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.password-req'))+" ")]):_vm._e(),(!_vm.$v.form.password.minLength)?_c('div',[_vm._v(_vm._s(_vm.$t('form.password-min', { min: 8 })))]):_vm._e(),(!_vm.$v.form.password.maxLength)?_c('div',[_vm._v(_vm._s(_vm.$t('form.password-max', { max: 30 })))]):_vm._e(),(!_vm.$v.form.password.valid)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.password-not-valid'))+" ")]):_vm._e()]):_vm._e()])])])]),_c('hr',{staticClass:"mt-2 mb-1"}),_c('div',{staticClass:"mt-3 text-center text-md-left"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.trySubmit}},[_vm._v(" "+_vm._s(_vm.isNew ? _vm.$t('btn.add-company') : _vm.$t('btn.save-changes'))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }