<template>
  <Vertical layout="vertical" class="layout-main">
    <slot />
  </Vertical>
</template>

<script>
import Vertical from "./vertical";

export default {
  components: {
    Vertical,
  },
  // data() {
  //   return {};
  // },
  // computed: {},
};
</script>
