<template>
  <form action="#" @submit.prevent="handleSubmit">
    <div class="common-modal-headline">
      <h4 class="common-modal-title">
        {{ isNew ? $t('company.add-new-company') : $t('company.edit-company') }}
      </h4>
      <span class="material-symbols-rounded common-modal-close" @click="$emit('onClose')">
        close
      </span>
    </div>

    <hr class="my-3" />

    <b-alert variant="danger" dismissible class="mt-3" :show="Boolean(error)">{{ error }}</b-alert>

    <div class="row">
      <!-- left col -->
      <div class="col-md-6">

        <div class="form-group mb-3">
          <label class="required">{{ $t('form.company-name') }}</label>
          <div class="input-group input-group-merge">
            <input v-model="form.name" class="form-control" :placeholder="$t('form.company-name-placeholder')" :class="{
              'is-invalid': submitted && $v.form.name.$error,
            }" />
            <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
              <div v-if="!$v.form.name.required">
                {{ $t('form.company-name-req') }}
              </div>
              <div v-if="!$v.form.name.minLength">{{ $t('form.company-name-min', { min: 5 }) }}</div>
              <div v-if="!$v.form.name.maxLength">{{ $t('form.company-name-max', { max: 64 }) }}</div>
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="required">{{ $t('form.fio') }}</label>
          <div class="input-group input-group-merge">
            <input v-model="form.fio" class="form-control" :placeholder="$t('form.fio-placeholder')" :class="{
              'is-invalid': submitted && $v.form.fio.$error,
            }" />
            <div v-if="submitted && $v.form.fio.$error" class="invalid-feedback">
              <div v-if="!$v.form.fio.required">
                {{ $t('form.fio-req') }}
              </div>
              <div v-if="!$v.form.fio.minLength">{{ $t('form.fio-min', { min: 5 }) }}</div>
              <div v-if="!$v.form.fio.maxLength">{{ $t('form.fio-max', { max: 64 }) }}</div>
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <label class="required">{{ $t('form.timezone') }}</label>
          <div class="input-group input-group-merge">
            <multiselect v-model="form.timezone" :options="timezones" :multiple="false" group-values="zones"
              group-label="group" :group-select="false" :allow-empty="false" :maxHeight="240" tag-placeholder=""
              :placeholder="$t('form.timezone-placeholder')" class="w-100" :class="{
                'is-invalid': submitted && $v.form.timezone.$error,
              }" />
            <div v-if="submitted && $v.form.timezone.$error" class="invalid-feedback">
              <div v-if="!$v.form.timezone.required">{{ $t('form.timezone-req') }}</div>
            </div>
          </div>
        </div>

      </div>

      <!-- right col -->
      <div class="col-md-6">

        <div class="form-group mb-3">
          <label class="required">{{ $t('form.phone') }}</label>
          <div class="input-group input-group-merge">
            <input v-model="form.phone" class="form-control" :placeholder="$t('form.phone-placeholder')" :class="{
              'is-invalid': submitted && $v.form.phone.$error,
            }" />
            <div v-if="submitted && $v.form.phone.$error" class="invalid-feedback">
              <div v-if="!$v.form.phone.required">
                {{ $t('form.phone-req') }}
              </div>
              <div v-if="!$v.form.phone.minLength">{{ $t('form.phone-min', { min: 5 }) }}</div>
              <div v-if="!$v.form.phone.maxLength">{{ $t('form.phone-max', { max: 20 }) }}</div>
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label :class="{ required: isNew }">{{ $t('form.email') }}</label>
          <div class="input-group input-group-merge">
            <input v-model="form.email" :readonly="!isNew" class="form-control"
              :placeholder="$t('form.email-placeholder')" :class="{
                'is-invalid': isNew && submitted && $v.form.email.$error,
              }" />
            <div v-if="isNew && submitted && $v.form.email.$error" class="invalid-feedback">
              <div v-if="!$v.form.email.required">
                {{ $t('form.email-req') }}
              </div>
              <div v-if="!$v.form.email.email">{{ $t('form.email-not-valid') }}</div>
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <label :class="{ required: isNew }">{{ $t('form.password') }}</label>
          <div class="input-group input-group-merge">
            <input v-model="form.password" :type="showPassw ? 'text' : 'password'" class="form-control"
              :placeholder="$t('form.password-placeholder')" :class="{
                'is-invalid': isNew && submitted && $v.form.password.$error,
              }" />
            <div class="input-group-append" @click="showPassw = !showPassw" role="button">
              <div class="input-group-text">
                <span v-if="showPassw" class="material-symbols-rounded fz-18">visibility</span>
                <span v-else class="material-symbols-rounded fz-18">visibility_off</span>
              </div>
            </div>
            <div v-if="isNew && submitted && $v.form.password.$error" class="invalid-feedback">
              <div v-if="!$v.form.password.required">
                {{ $t('form.password-req') }}
              </div>
              <div v-if="!$v.form.password.minLength">{{ $t('form.password-min', { min: 8 }) }}</div>
              <div v-if="!$v.form.password.maxLength">{{ $t('form.password-max', { max: 30 }) }}</div>
              <div v-if="!$v.form.password.valid">
                {{ $t('form.password-not-valid') }}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <hr class="mt-2 mb-1" />

    <div class="mt-3 text-center text-md-left">
      <button class="btn btn-primary" type="submit" :disabled="trySubmit">
        {{ isNew ? $t('btn.add-company') : $t('btn.save-changes') }}
      </button>
    </div>

    <!-- <pre>company: {{ company }}</pre> -->
    <!-- <pre>form: {{ form }}</pre> -->
    <!-- <div>
      <pre>form.timezone: {{ form.timezone }}</pre>
    </div> -->
    <!-- <div>
      <pre>timezones: {{ timezones }}</pre>
    </div> -->
  </form>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import Multiselect from "vue-multiselect";

import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  props: {
    company: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      error: "",

      form: {
        name: "",
        fio: "",
        phone: "",
        email: "",
        timezone: "",
        password: ""
      },

      showPassw: false,

      submitted: false,
      trySubmit: false,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.initForm();
  },
  computed: {
    ...mapState(useGlobalStore, ['timezones']),
    isNew() {
      return !this.company?.id;
    },

    formRules() {
      let rules = {};

      rules.name = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(64),
      };

      rules.fio = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(64),
      };

      rules.phone = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(20),
      };

      rules.timezone = {
        required,
      };

      if (this.isNew) {
        rules.email = {
          required,
          email,
        };

        rules.password = {
          required,
          // valid: function (value) {
          //   const containsUppercase = /[A-Z]/.test(value);
          //   const containsLowercase = /[a-z]/.test(value);
          //   const containsNumber = /[0-9]/.test(value);
          //   const containsSpecial = /[#?!@$%^&*-]/.test(value);
          //   return (
          //     containsUppercase &&
          //     containsLowercase &&
          //     containsNumber &&
          //     containsSpecial
          //   );
          // },
          minLength: minLength(8),
          maxLength: maxLength(30),
        };
      }

      return rules;
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['editCompany', 'createCompany']),
    initForm() {
      if (!this.isNew) {
        this.form = {
          id: this.company.id,
          name: this.company.name,
          fio: this.company.fio,
          phone: this.company.phone,
          timezone: this.company.timezone, // ### search and set from timezones
          email: this.company.email,
          password: this.company.password,
        };
      }
    },
    async handleSubmit() {
      this.submitted = true;
      this.error = "";

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.trySubmit = true;
        let req = null

        if (this.isNew) {
          // Add new driver
          // console.log('new company, data: ', this.form)
          req = await this.createCompany({ ...this.form })
        } else {
          // console.log('edit existed company, data: ', this.form)
          req = await this.editCompany({ ...this.form })
        }

        if (req?.data?.success) {
          this.$emit("onSubmit");
        } else {
          // console.log('login page, req: ', req)
          this.error = this.$t('msg.error')

          if (req.message && req.response?.data?.error) {
            this.error = req.message + ': ' + this.$t('msg.' + req.response.data.error)
          }
        }

        this.trySubmit = false;
      }
    },
  },
  watch: {},
};
</script>
