<template>
  <Layout>
    <div class="row align-items-center text-center page-headline mb-3">
      <div class="col-md-6 text-md-left">
        <h1 class="display-3">{{ $t('page.admin.companies') }}</h1>
      </div>

      <div class="col-md-6 text-md-right">
        <button class="btn btn-primary" @click.prevent="addCompany">
          <span class="material-symbols-rounded mr-2"> add </span> {{ $t('btn.add-company') }}
        </button>
      </div>
    </div>

    <div v-if="loading">
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </div>
    <div v-else>
      <b-alert v-if="error" variant="danger" class="mt-3" dismissible :show="Boolean(error)">{{ error }}</b-alert>
      <template v-else>

        <div class="card">
          <div class="card-body">

            <div class="search-field mb-2">
              <b-form-input v-model="search" :placeholder="$t('company.search-placeholder')" class="search-field-input"
                @keyup.enter="searchStart" />
              <button v-if="search.length >= 3" class="search-field-btn search-field-btn-reset" @click="searchReset"
                :title="$t('btn.reset')">
                <span class="material-symbols-rounded search-field-ico">
                  close
                </span>
              </button>
              <button class="search-field-btn" @click="searchStart" :title="$t('btn.search')">
                <span class="material-symbols-rounded search-field-ico">
                  search
                </span>
              </button>
            </div>

            <div class="row mb-md-2 text-center table-filters">

              <div class="col-sm-12 col-md-auto text-md-left">
                <div class="table-filters-label d-block d-md-inline-flex align-items-center flex-wrap mr-2">
                  <label class="d-inline-flex align-items-center mr-2">
                    <span class="text-nowrap">{{ $t('form.filter-by-block') }}:</span>
                    <b-form-select v-model="block" size="sm" class="form-control form-control-sm ml-2">
                      <b-form-select-option value="" selected>{{ $t('form.state-all') }}</b-form-select-option>
                      <b-form-select-option v-for="b in blocks" :value="b.id" :key="b.id">{{ b.name
                      }}</b-form-select-option>
                    </b-form-select>
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-auto ml-auto mt-2 mt-md-0">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <span class="text-nowrap">{{ $t('form.quantity') }}:</span>
                    <b-form-select v-model="per_page" size="sm" :options="pageOptions"
                      class="form-control form-control-sm ml-2"></b-form-select>
                  </label>
                </div>
              </div>

            </div>
            <div class="table-responsive mb-0">

              <!-- <div>
                <pre>block: {{ block }}</pre>
              </div> -->
              <!-- <div>
                <pre>blocks: {{ blocks }}</pre>
              </div> -->
              <!-- <div>
                <pre>companies: {{ companies }}</pre>
              </div> -->
              <!-- <div>
                <pre>page: {{ page }}</pre>
              </div> -->
              <!-- <div>
                <pre>per_page: {{ per_page }}</pre>
              </div> -->
              <!-- <div>
                <pre>tableFields: {{ tableFields }}</pre>
              </div> -->
              <!-- <div>
                <pre>tableData: {{ tableData }}</pre>
              </div> -->

              <b-table :items="tableData" :fields="tableFields" responsive="sm" :per-page="per_page"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                show-empty :tbody-tr-class="rowClass">

                <template #head(id)="data">
                  {{ $t('table.id') }}
                </template>
                <template #head(name)="data">
                  {{ $t('table.name') }}
                </template>
                <template #head(fio)="data">
                  {{ $t('table.fio') }}
                </template>
                <template #head(timezone)="data">
                  {{ $t('table.timezone') }}
                </template>
                <template #head(email)="data">
                  {{ $t('table.login') }}
                </template>
                <template #head(phone)="data">
                  {{ $t('table.phone') }}
                </template>

                <template #cell(action)="data">
                  <a href="#" @click.prevent="handleEditCompany(data.item)" class="action-edit"
                    :title="$t('btn.edit')"><span class="material-symbols-rounded fz-24 v-middle mx-1">
                      edit
                    </span></a>
                  <a v-if="!data.item.block" href="#" @click.prevent="confirmBlock(data.item)" class="action-block"
                    :title="$t('btn.block')"><span class="material-symbols-rounded fz-28 v-middle mx-1">
                      Block
                    </span></a>
                  <a v-else href="#" @click.prevent="confirmUnBlock(data.item)" class="action-unblock"
                    :title="$t('btn.unblock')"><span class="material-symbols-rounded fz-28 v-middle mx-1">
                      Block
                    </span></a>
                  <!-- <span class="material-symbols-rounded fz-24 v-middle mx-1">
                    lock
                  </span> -->

                  <!-- <span class="material-symbols-rounded fz-24 v-middle mx-1">
                    lock_open
                  </span> -->
                </template>

                <template #empty>
                  <h4 class="text-center">
                    {{ $t('table.no-data') }}
                  </h4>
                </template>

              </b-table>
            </div>
            <div class="row mb-3">
              <div class="col-12 text-center">
                <div class="dataTables_paginate paging_simple_numbers">
                  <b-pagination v-model="page" :total-rows="totalRows" :per-page="per_page"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <b-modal v-model="showModal" size="lg" hide-header hide-footer centered>
      <formCompany :company="company" @onSubmit="submitForm" @onClose="showModal = false" />
    </b-modal>

  </Layout>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import Layout from "@/views/layouts/main";
import formCompany from "@/components/admin/form-company";

export default {
  metaInfo() {
    return {
      title: this.$t('app.title') + " | " + this.$t('page.admin.companies'),
    };
  },
  components: {
    Layout,
    formCompany,
  },
  data() {
    return {
      loading: false,
      error: "",

      search: "",
      block: "",

      tableData: [],
      totalRows: 0,
      page: 1,
      pageOptions: [25, 50, 100],
      per_page: 25,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableFields: [
        {
          key: "id",
          // sortable: true,
          class: "cell-id",
        },
        {
          key: "name",
        },
        {
          key: "fio",
        },
        {
          key: "timezone",
        },
        {
          key: "phone",
        },
        {
          key: "email",
        },
        {
          key: "action",
          label: "",
          class: "cell-action",
        },
      ],

      showModal: false,
      company: "",
    };
  },
  created() {
    this.paramFromQuery();
    this.loadTableData(false);
  },
  computed: {
    blocks() {
      return [
        {
          id: 0,
          name: this.$t('form.state-unblock')
        },
        {
          id: 1,
          name: this.$t('form.state-block')
        }
      ]
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['getCompanyList', 'editCompany']),
    async setQuery(newQuery, toHistory = false) {
      const query = Object.assign({}, { ...this.$route.query, ...newQuery });

      // delete empty fields
      Object.keys(query).forEach(key => {
        if (query[key] === undefined || query[key] === null || query[key] === '') {
          delete query[key];
        }
      })

      if (toHistory) {
        await this.$router.push({ query })
      } else {
        await this.$router.replace({ query }).catch(err => { })
      }
    },
    paramFromQuery() {
      this.search = (this.$route.query?.search?.length >= 3) ? this.$route.query.search : ""
      this.block = this.$route.query?.block || ""
      this.per_page = Number(this.$route.query?.per_page) || 25
      this.page = Number(this.$route.query?.page) || 1
      // TODO: sort
    },
    async loadTableData(silent = true) {
      this.paramFromQuery();

      try {
        if (!silent) {
          this.loading = true;
        }
        this.error = "";

        const response = await this.getCompanyList({ per_page: this.per_page, page: this.page, block: this.block, qname: this.search });

        this.tableData = response.data.list.list || [];
        this.totalRows = response.data.list.total;

      } catch (error) {
        console.log("loadTableData Error: ", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    confirmBlock(company) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to BLOCK company: ${company.name} ?`,
          {
            title: "Block confirmation",
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: this.$t('btn.yes'),
            cancelTitle: this.$t('btn.cancel'),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (sure) => {
          if (sure) {
            await this.editCompany({
              id: company.id,
              block: 1 // 1 - block
            })
            this.loadTableData()
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    confirmUnBlock(company) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to UNBLOCK company: ${company.name} ?`,
          {
            title: "Unblock confirmation",
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: this.$t('btn.yes'),
            cancelTitle: this.$t('btn.cancel'),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (sure) => {
          if (sure) {
            await this.editCompany({
              id: company.id,
              block: 0 // 0 - unblock
            })
            this.loadTableData()
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    handleEditCompany(company) {
      this.company = { ...company };
      this.showModal = true;
    },
    addCompany() {
      this.company = {};
      this.showModal = true;
    },
    submitForm() {
      this.loadTableData(false);
      this.showModal = false;
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.block !== 0) return 'table-warning'
    },
    searchStart() {
      if (this.search.length >= 3 || this.search === "") {
        this.setQuery({ page: 1, search: this.search }, true)
      }
    },
    searchReset() {
      this.search = ""
      this.setQuery({ page: 1, search: this.search }, true)
    }
  },
  watch: {
    // watch query like nuxt watchQuery
    '$route.query'(q, old) {
      if (
        ((q.search || old.search) && (q.search !== old.search)) ||
        ((q.page || old.page) && (q.page !== old.page)) ||
        ((q.per_page || old.per_page) && (q.per_page !== old.per_page)) ||
        ((q.block || old.block) && (q.block !== old.block))
      ) {
        this.loadTableData()
      }
    },
    page(page) {
      this.setQuery({ page }, true)
    },
    per_page(per_page) {
      this.setQuery({ page: 1, per_page }, true)
    },
    block(block) {
      this.setQuery({ page: 1, block }, true)
    },
  },
};
</script>
<style scoped lang="scss"></style>
